/* ----------------------Hiring List--------------- */

.warning_box{
    position: fixed;
    top : 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    scale: 0;
}
.inner_warning_box{
    border: 1px solid black;
    background-color: white;
    width : 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap : 10px;
    padding : 20px;
}
.warning_box button{
    padding : 3px 12px;
    color: white;
    font-weight: 500;
    border-radius: 3px;
}