@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
body{
    overflow-x: hidden;
}
.head
{
    height: min-content;
    width: 100%;
    background: linear-gradient(to bottom right, #BDE8EB, white);
}
P{
    margin-bottom: 0;
}
.Navbar
{
    width: 100vw;
    padding: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 0 6px gray;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 8;
}
.logo2
{
    display: none;
    height: 50px;
}
.Navbar-div
{
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
ul
{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 10px;
}
a
{
    text-decoration: none;
    color: black;
    font-weight: 500;
}

.register-div{
    display: flex;
    gap : 5px;
}
.register-div button {
    outline: none;
}
.register-div button:focus{
    outline: none;
    /* border: none; */
    box-shadow: none;
}
#signup-button{
    background-color: #009933;
    color: white;
    font-weight: 400;
    /* border: 1px solid skyblue; */
    transition: all linear 0.2s;
    padding : 10px 15px;
    border-radius: 5px;
}
#login-button{
    /* background-color: skyblue; */
    color: white;
    font-weight: 400;
    /* border: 1px solid skyblue; */
    transition: all linear 0.2s;
    padding : 10px 15px;
    border-radius: 5px;
    background-color: #009933;
}
#signup-button:hover{
    background-color: #017628;
}
#login-button:hover{
    background-color: #017628;
}
#nav_link{
    margin-bottom: 0;
}
#nav_link a, #side_bar a{
    text-decoration: none;
    color : black;
}
#nav_link a:hover, #side_bar a:hover{
    color : #003399;
}
.side-bar
{
    height: 100vh;
    width:70%;
    display: none;
    position: fixed;
    background-color: white;
    top: 90px;
    right: 0px;
    transition: 0.9s;
    z-index: 4;
    transform: translateX(100%);
}


.side-bar .navbar-link
{
    padding: 30px;
    flex-direction: column;
    gap : 15px;
}
.side-bar ul a:hover{
    color : #003399;
}
.Navbar ul .active{
    color : #003399;
}
.Navbar ul a:hover{
    color : #003399;
}
.side-icons
{
    display: none;
}
.icon1
{
    transform: rotate(45deg);
    transform-origin:25%;
}
.icon2
{
    transform: rotate(-45deg);
    transform-origin:25%;
}

/* ......Second sec .... */

.full-body
{
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-sec{
    padding-top: 100px;
}
@media screen and (max-width : 770px) {
    .hero-sec{
        padding-top: 150px;
    }   
    #carouselExampleInterval{
        margin-top: 100px;
    }
}
@media screen and (max-width : 450px) {
     
}

.inner-hero
{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
}
.inner-hero img
{
    width: 45%;
}
.inner-hero-content
{
    width: 50%;
}
.inner-hero-content>*
{
    margin-bottom: 20px;
}
.inner-hero-content h1
{
    font-size: 50px;
    font-family: Poppins;
    line-height: 60px;
    font-weight: 500;
}

.inner-hero-content p
{
    font-size: 18px;
    font-family: Poppins;
}

.inner-hero-content button
{
    height: 46px;
    width: 176px;
    border: 0px;
    background-color:#1c2431;
    color: white;
    font-weight: bold;
    border-radius: 6px;
}

@media(max-width:992px)
{
    .inner-hero
{
    width: 90%;
}
    .inner-hero-content h1
{
    font-size: 44px;
    font-family: Poppins;
    line-height:auto;
}

#nav_link{
    display: none;
}
#register-div{
    display: none;
}
#side_icons{
    display: block;
}
.side-bar{
    display: block;
}
}

@media(max-width:768px)
{
    .logo1{
        display: none;
    }
    .logo2{
        display: block;
    }
    .inner-hero-content
{
    width: 100%;
}
    .inner-hero
{
    width: 90%;
}
    .inner-hero-content h1
{
    font-size: 44px;
    font-family: Poppins;
    line-height:auto;
}

.inner-hero img
{
    width: 80%;
}
}

@media(max-width:480px)
{
    .inner-hero-content h1
    {
        font-size: 34px;
        font-family: Poppins;
        line-height:37px;
    }
    .inner-hero-content button
{
    height: 36px;
    width: 146px;
}
}


/*Team*/


/* Review */

.review {
    width: 100%;
    /* height: 100vh; */
    padding: 70px 0;
}

.review h1 {
    font-size: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.review h1 span {
    margin-left: 15px;
    color: #183883;
    font-family: mv boli;
}

.review h1 span::after {
    content: '';
    width: 100%;
    height: 2px;
    background: #183883;
    position: relative;
    bottom: 15px;
    display: block;
}

.review .review_box {
    width: 95%;
    margin: 70px auto;
    display: flex;
    flex-wrap: wrap; /* Added for better responsiveness */
    justify-content: center;
}

.review .review_box .review_card {
    width: 350px;
    height: 250px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 8px 20px;
    margin: 15px; /* Adjusted margin for better spacing */
    transition: transform 0.4s; /* Smooth transition for hover effect */
}

.review .review_box .review_card:hover {
    transform: scale(1.05); /* Added a slight scale effect on hover */
}

.review .review_box .review_card .review_profile {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.3s;
}

.review .review_box .review_card:hover .review_profile {
    transform: translateY(-60px);
}

.review .review_box .review_card .review_profile img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    border: 5px solid #cccccc;
}

.review .review_box .review_card .review_text {
    text-align: center;
}

.review .review_box .review_card .review_text .name {
    color: #000;
    transition: 0.3s;
}

.review .review_box .review_card:hover .review_text .name {
    transform: translateY(-50px);
}

.review .review_box .review_card .review_text .review_icon {
    color: #183883;
    transition: 0.3s;
}

.review .review_box .review_card:hover .review_text .review_icon {
    transform: translateY(-30px);
}

.review .review_box .review_card .review_text .review_social i {
    margin: 5px 1px;
    font-size: 15px;
    opacity: 0;
    cursor: pointer;
    transition: 0.3s;
}

.review .review_box .review_card:hover .review_text .review_social i {
    opacity: 1;
    transform: translateY(-8px);
}

.review .review_box .review_card .review_text p {
    text-align: center;
    line-height: 22px;
    transition: 0.3s;
}

.review .review_box .review_card:hover .review_text p {
    margin-top: 5px;
}

/* Responsive Design */

@media (max-width: 768px) {
    .review {
        height: auto;
        padding: 50px 0;
    }

    .review h1 {
        font-size: 40px;
    }

    .review .review_box .review_card {
        width: 300px;
        height: auto;
    }

    .review .review_box .review_card .review_profile img {
        width: 150px;
        height: 150px;
    }

    .review .review_box .review_card .review_text .name,
    .review .review_box .review_card .review_text .review_icon {
        transform: none;
    }

    .review .review_box .review_card .review_text .review_social i {
        transform: none;
    }

    .review .review_box .review_card:hover .review_text .name,
    .review .review_box .review_card:hover .review_text .review_icon,
    .review .review_box .review_card:hover .review_text .review_social i {
        transform: none;
    }
}

@media (max-width: 480px) {
    .review h1 {
        font-size: 30px;
        text-align: center;
    }

    .review .review_box .review_card {
        width: 250px;
        height: auto;
    }

    .review .review_box .review_card .review_profile img {
        width: 120px;
        height: 120px;
    }

    .review .review_box .review_card .review_text .name,
    .review .review_box .review_card .review_text .review_icon,
    .review .review_box .review_card .review_text .review_social i {
        transform: none;
    }

    .review .review_box .review_card:hover .review_profile {
        transform: none;
    }

    .review .review_box .review_card:hover .review_text p {
        margin-top: 0;
    }
}


.content-side {
    height: min-content;
    width: 100vw;
    /* background-color: #f7f7f7; */
    /* background-image: url("IMG-20230817-WA0034.jpg"); */
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #577592;
    padding: 50px 0px;
}

.inner-main-box {
    height: 62%;
    width: 90%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: start;
}

.feature-box-img{
    padding : 30px;
    border-radius: 100%;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.feature-box-img>img{
    width : 50px;
}
.boxes {
    /* height: 215px; */
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap : 20px;
    align-items: center;
    margin-top: 60px;
    padding : 10px;
    text-align: center;
}

.boxes h4 {
    color: #003399;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0px;
    text-align: center;
}
.feature-line{
    height : 4px;
    background-color: #009933;
    width : 25%;
}
.feature-line1{
    height : 4px;
    background-color: #009933;
    width : 25%;
}
/* .box-1 img {
    height: 40px;
    width: 40px;
} */



.second-hero {
    height: min-content;
    width: 100vw;
    background-color: rgb(247, 249, 250);
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width : 990px) {
    .boxes{
        width : 32%;
    }
}
@media screen and (max-width : 770px) {
    .boxes{
        width : 40%;
    }
    .inner-main-box{
        width : 100%;
    }
}
@media screen and (max-width : 450px) {
    .boxes{
        width : 80%;
    }
    .inner-main-box{
        width : 100%;
    }
}

/* ----------------------SERVICES------------------------- */

.eighth-section {
    padding-top:70px;
}
.eighth-lists{
    width : 90%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: auto;
}
.services-heading, .review-heading, .team-heading{
    text-align: center;
    font-size: 2rem;
    color : #009933;
    font-weight: 500;
    font-style: italic;
    margin-bottom: 0;
    margin-top: 50px;
}
.eighth-lists .first{
    width : 30%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap : 20px;
    margin-top: 50px;
}
.content{
    width : 100%;
}
.content ul{
    width : 100%;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap : 0px;
    padding: 0;
}
.heading h4{
    font-size: 1.3rem;
    font-weight: 400;
}
.content ul li{
    width: 100%;
    color : black;
    font-weight: 400;
    font-size: 1rem;
    padding : 8px 0px;
    display: flex;
    justify-content: start;
    gap : 10px;
    align-items: center;
    /* border-bottom: 1px solid #adbabb7e; */
}
.content ul li>img{
    height : 25px;
}
@media (max-width : 990px) {
    .eighth-lists{
        flex-wrap: wrap;
    }
    .eighth-lists .first{
        min-width: 45%;
    }
}
@media (max-width : 770px) {
    .eighth-lists .first{
        min-width: 100%;
    }
}
@media (max-width : 470px){
    .eighth-lists{
        padding : 0px 30px;
    }
}

/* ----------------SERVICES POP-UP ---------------------- */
.service-pop-up{
    position: fixed;
    top : 100px;
    left : 0;
    width : 100%;
    height : 100vh;
    display: flex;
    justify-content: center;
    /* background-color: #000; */
    z-index: 9;
    scale: 0;
    transition: all linear 0.3s;
    transform-origin: center;
}
.service-pop-up .eighth-section{
    border : 1px solid black;
    background-color: #fff;
    padding : 20px;
    width : 80%;
    height : calc(100vh - 100px);
    position: relative;
    overflow-y: scroll;
}
.service-pop-up .eighth-section::-webkit-scrollbar{
    width
    : 8px;
}
.service-pop-up .eighth-section::-webkit-scrollbar-thumb{
    background-color : rgb(210, 194, 194);
    border-radius: 50px;
}
.service-close{
    position: absolute;
    top : 20px;
    right : 20px;
    height : 20px;
}
.service-pop-up .first{
    margin: 0px;
}
@media (max-width : 770px) {
    .service-pop-up .eighth-section{
        width : 95%;
    }
}
@media (max-width : 450px) {
    .service-pop-up .eighth-section{
        padding : 0;
    }
    .service-close{
        top : 10px;
        right : 10px;
    }
}

/* ------------------------TEAM----------------------- */
.team-section{
    width: 100%;
    padding : 80px;
}
.team{
    display: flex;
    flex-direction: column;
    gap : 20px;
}
.team-box-img{
    width : 30%;
}
.team-box-img>img{
    width : 100%;
}
.team-box-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    /* padding : 0px 50px; */
    gap : 20px;
    width : 55%;
}
.team-box-content>h1{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}
.team-box{
    width : 100%;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width : 990px) {
    .team-section{
        padding : 50px;
    }
}
@media screen and (max-width : 770px) {
    .team-box-img{
        width : 40%;
    }
}
@media screen and (max-width : 550px) {
    .team-box{
        flex-wrap: wrap;
    }
    .team-box-img{
        width : 100%;
    }
    .team-box-content{
        width : 100%;
    }

}
@media screen and (max-width : 450px) {
    .team-box-content{
        align-items: center;
        text-align: center;
    }
}



/* --------------------TEAM POP-UP-------------------- */
.team-pop-up{
    position: fixed;
    top : 100px;
    left : 0;
    /* background-color: #000; */
    width : 100%;
    height : 100vh; 
    z-index: 9;
    scale: 0;
    transition: all linear 0.3s;
    transform-origin: center;
    display: flex   ;
    justify-content: center;
}
.team-pop-up .team-section{
    width : 70%;
    height: calc(100vh - 100px);
    padding: 20px;
    border : 1px solid black;
    background-color: #fff;
    position: relative;
    overflow-y: scroll;
}
.team-pop-up .team-section::-webkit-scrollbar{
    width
    : 8px;
}
.team-pop-up .team-section::-webkit-scrollbar-thumb{
    background-color : rgb(210, 194, 194);
    border-radius: 50px;
}
.team-pop-up .team-heading{
    margin: 0px;
}
.team-pop-up .team{
    gap : 5px;
}
.team-close{
    position: absolute;
    top : 20px;
    right : 20px;
    height : 20px;
}
@media (max-width : 770px) {
    .team-pop-up .team-section{
        width : 95%;
    }
}
@media (max-width : 450px) {
    .team-pop-up .team-section{
        padding : 10px;
    }
    .team-close{
        top : 10px;
        right : 10px;
    }
}



/* ----------------------PRESENCE--------------------- */
.presence{
    padding : 0 80px;
}
.presence .team-box-img>*{
    width : 100%;
    height : 100%;
}
.presence .team-box-img{
    width : 46%;
}
.presence .team-box{
    align-items: center;
}

.presence .team-box-content{
    gap : 10px;
    width : 48%;
}
.presence .team-box-content>h1{
    margin-top: 10px;
}
#INBR{
    fill : #009933;
}
#INDL{
    fill : #009933;
}
#INCH{
    fill : #009933;
}
#INUT{
    fill: #009933;
}
#INPB{
    fill: #009933;
}
#INMH{
    fill: #009933;
}
#INWB{
    fill: #009933;
}
#INJH{
    fill: #009933;
}
#INBR{
    fill: #009933;
}

@media (max-width : 770px) {
    .presence .team-box{
        flex-wrap: wrap;
    }
    .presence .team-box-img{
        width : 100%;
    }
    .presence .team-box-content{
        width: 100%;
    }

}

@media (max-width : 770px){
    .presence{
        padding : 0 20px;
    }
}


/* ---------------------PRESENCE-POP-UP------------------------------ */
.presence-pop-up{
    position: fixed;
    top : 100px;
    left : 0;
    /* background-color: #000; */
    width : 100%;
    height : 100vh; 
    z-index: 9;
    scale: 0;
    transition: all linear 0.3s;
    transform-origin: center;
    display: flex   ;
    justify-content: center;
}

.presence-pop-up .presence{
    width : 70%;
    height: calc(100vh - 100px);
    padding: 20px;
    border : 1px solid black;
    background-color: #fff;
    position: relative;
    overflow-y: scroll;
}
.presence-pop-up .presence::-webkit-scrollbar{
    width
    : 8px;
}
.presence-pop-up .presence::-webkit-scrollbar-thumb{
    background-color : rgb(210, 194, 194);
    border-radius: 50px;
}
.presence-pop-up .team-heading{
    margin: 0px;
}
.presence-pop-up .team{
    gap : 5px;
}
.presence-close{
    position: absolute;
    top : 20px;
    right : 20px;
    height : 20px;
}
@media (max-width : 770px) {
    .presence-pop-up .presence{
        width : 95%;
    }
}
@media (max-width : 450px) {
    .presence-pop-up .presence{
        padding : 10px;
    }
    .presence-close{
        top : 10px;
        right : 10px;
    }
}


/* ---------------------BANNER-------------------- */
.banner {
    padding: 20px;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 50px 0;
    background-color: #003399;
    text-align: center;
}

.banner h2 {
    font-size: 2.7rem;
    color: white;
}

.banner p {
    font-size: 1.3rem;
    color: white;
}

@media screen and (max-width: 768px) {
    .banner h2 {
        font-size: 2.5rem;
    }
    .banner p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .banner h2 {
        font-size: 2rem;
    }
    .banner p {
        font-size: 1.2rem;
    }
    .service-content-card {
        width: 45%;
        font-size: 0.7rem;
    }
}

@media (min-width: 451px) and (max-width: 770px) {
    .service-content-card {
        width: 20%;
    }
}

@media (min-width: 770px) {
    .service-content-card {
        width: 15%;
    }
}



/* ------------------FOOTER------------------ */
#picassoFooter {
    background: linear-gradient(to bottom right, #BDE8EB, white);
    box-shadow: 0 0 6px gray;
    color: black;
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: start;
    font-family: 'Arial', sans-serif;
    font-size: 0.9rem;
}

#picassoFooter h3{
    color : #009933;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.footer-line{
    width : 70px;
    height : 3px;
    background-color: #003399;
    margin-bottom: 10px;
}

.footer-contact{
    width : 27%;
}

.footer-navigation, .footer-contact, .footer-art {
    margin: 10px;
    /* width : 30%; */
}

.footer-navigation h3, .footer-contact h3, .footer-social h3 {
    color: #f0f0f0;
    margin-bottom: 15px;
}

.footer-navigation ul, .footer-social .social-icons {
    list-style: none;
    padding: 0;
    color : black;
}
.footer-navigation ul{
    display: block;
}

.footer-navigation ul li, .social-icon {
    margin-bottom: 10px;
    transition: transform 0.2s ease-in-out;
}

.footer-navigation ul li a, .social-icon {
    color: black;
    text-decoration: none;
}

.social-icons {
    display: flex;
    /* padding: 8px; */
    /* background-color: #333; */
    margin-right: 5px;
    gap : 10px;
}

.social-icon img{
    width : 30px;
}

.social-icon:hover, .footer-navigation ul li a:hover {
    transform: scale(1.1);
}
.footer-art{
    width : 35%;
}
.footer-art img {
    max-width: 200px;
    height: auto;
    /* margin-top: 20px; */
}

@media (max-width: 600px) {
    #picassoFooter {
        flex-direction: column;
        align-items: center;
    }

    .footer-contact{
        width : 70%;
    }

    .footer-art img {
        max-width: 60%;
    }
    /* .footer-art{
        display: flex;
        justify-content: center;
        align-items: center;
    } */
    #picassoFooter{
        /* justify-content: start; */
        align-items: start;
    }
}

@media (max-width: 600px) {
    .footer-contact{
        width : 100%;
    }
    .footer-art{
        width : 90%;
    }
    .footer-art>img{
        max-width: 100%;
        height: 70px;
    }
}


.sub-footer{
    width : 100%;
    padding : 20px;
    text-align: center;
    background: linear-gradient(to bottom right, #BDE8EB, white);   
}


/* --------------------Enquiry Secton------------------- */

.enquiry-section{
    width: 100%;
    position: fixed;
    top : 100px;
    display: flex;
    justify-content: center;
    scale: 0;
    transition: all linear 0.3s;
}
.enquiry-form{
    height: calc(100vh - 120px);
    width : 50%;
    overflow-y: scroll;
    background-color: white;
    border : 1px solid black;
    padding : 30px;
    display: flex;
    flex-direction: column;
    gap : 10px;
    z-index: 9;
    position: relative;
    /* border-radius: 10px; */
}
.enquiry-form::-webkit-scrollbar{
    width
    : 8px;
}
/* .enquiry-form::-webkit-scrollbar-track{
     background-color: #f1f1f1; 
} */
.enquiry-form::-webkit-scrollbar-thumb{
    background-color : rgb(210, 194, 194);
    border-radius: 50px;
}
.enquiry-form>h2{
    color : #009933;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0px;
}
.enquiry-form>p{
    text-align: center;
    color: gray;
}
.input-box{
    display: flex;
    gap : 10px;
    justify-content: space-between;
}
.input-box>*{
    width: 48%;
}
.enquiry-form>form{
    display: flex;
    flex-direction: column;
    gap : 10px;
    margin-top: 30px;
}
.input-div{
    display: flex;
    flex-direction: column;
    /* gap : 10px; */
}
.form-label{
    margin-bottom: 0;
}
.input-div>input, .input-div>textarea, .input-div>select{
    padding : 5px;
    border: 1px solid gray;
    border-radius: 5px;
}
.enquiry-button{
    width : 100%;
    padding : 10px;
    background-color: skyblue;
    border-radius: 50px;
    border: none;
    outline: none;
    /* margin: auto; */
    color : white;  
    /* margin-top: 10px; */
}

@media (max-width : 990px) {
    .enquiry-form{
        width : 70%;
    }
}
@media (max-width : 770px) {
    .enquiry-form{
        width : 90%;
    }
}
@media (max-width : 450px) {
    .input-box{
        flex-direction: column;
    }
    .input-box>*{
        width: 100%;
    }
}



.enquiry-link{
    padding : 15px;
    background-color: #009933;
    position: fixed;
    right : 0;
    top : 50%;
    transform: translate(0%, 115%);
    color: white;
    rotate: -90deg;
    z-index: 99;
}

.enquiry-link>a>h3{
    color : white;
    font-size: 1.2rem;
    margin-bottom: 0;
}
.enquiry-close{
    position: absolute;
    top : 20px;
    right : 20px;
    height : 20px;
    cursor: pointer;
}