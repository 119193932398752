*{
    margin: 0;
    padding : 0;
    box-sizing: border-box;
}

.list_box{
    padding : 30px;
    flex-direction: column;
    gap : 10px;
}

.list{
    width : 100%;
    padding: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: white;
    /* border: 1px solid black; */
    border-radius: 5px;
    box-shadow: 2px 2px 5px gray;
    /* flex-wrap: wrap; */
    gap : 20px;
}
.list:hover{
    background-color: rgb(242, 242, 242);
}
.list_content{
    display: flex;
    width : 90%;
    justify-content: space-between;
}
.list-left{
    /* display: flex;
    gap : 20px;
    align-items: center; */
    width: 40%;
}
.list-middle{
    width : 20%;
}
.list-right{
    width : 20%;
}
.list>img{
    width : 30px;
    height: 30px;
    border-radius: 50%;
}
.ragister_div{
    width : 100%;
    padding : 30px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    flex-wrap: wrap;
}

@media (max-width : 770px) {
    .list_content{
        flex-wrap: wrap;
        justify-content: start;
        /* gap : 20px; */
        column-gap: 20px;
    }
    .list-left{
        width : 100%;
    }
    .list-middle{
        width: auto;
    }
    .list-right{
        width: auto;
    }
}

@media (max-width : 470px) {
    .list{
        padding: 10px;
    }
}



/* -------------search box-------- */
.search_container{
    display: flex;
    background-color: #fff;
    border-radius: 25px;
    align-items: center;
    padding-inline: 20px;
    padding-block: 10px;
    gap: 10px;
    margin-top: 10px;
}

.search__box{
    background: transparent;
}
.search__box:focus{
    border: none;
    outline: none;
}
  