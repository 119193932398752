/* Establisment Profile */


@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200;8..144,300;8..144,400&display=swap');

* {
  font-family: "Roboto Flex", sans-serif;
}

/* body {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D3EAFF;
} */

.line {
  width: 1px;
  height: 60%;
  margin: 0 20px;
  background-color: #BCBCBC;
}

#phone {
  width: 400px;
  height: 866px;
  padding: 5px;
  background: black;
  border: 5px solid gray;
  border-radius: 50px;
}

#screen {
  position: relative;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  overflow: auto;
  background-color: #f5f9fc;
  padding-top: 20px;
}


#screen #content {
  position: relative;
  width: 100%;
  /* height: 75%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: white; */
}

#screen #content #user-avatar {
  object-fit: cover;
  width : 170px;
  height : 170px;
  /* position: absolute; */
  /* left: calc(50% - 60px); */
  /* top: -60px; */
  border: 5px solid white;
  border-radius: 100%;
}

#screen #content #user-name {
  font-size: 24px;
  font-weight: bold;
  /* margin: 75px 0 0 0; */
  padding: 0;
}

#screen #content #user-location {
  font-size: 16px;
  color: #B4B4B0;
  /* margin: 5px 0 0 0; */
  padding: 0;
}

#screen #content #user-description {
  font-size: 16px;
  color: #6C7171;
  /* margin: 10px 0 0 0; */
  padding: 0;
}

#screen #content #user-general-values {
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 0 0;
}

#screen #content #user-general-values p {
  width: 30%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#screen #content #user-general-values p span:nth-child(1) {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

#screen #content #user-general-values p span:nth-child(2) {
  font-size: 14px;
  margin: 3px 0 0 0;
  color: #6C7171;
}

#screen #content #follow-btn {
  color: white;
  height: 35px;
  width: 110px;
  margin: 20px 0 0 0;
  border-radius: 15px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF7511;
  cursor: pointer;
}

#screen #content #follow-btn:hover {
  background: #F56700;
}

#screen #content #user-publications {
  position: relative;
  width: 100%;
  margin: 20px 0 0 0;
  display: grid;
	gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
}

#screen #content #user-publications img {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

#screen .container1 {
    width: 100%;
    padding: 20px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
}
#screen .profile-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}
#screen .profile-details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}
#screen .details-column {
    width: 100%;
    max-width: 400px;
}
#screen .details-column dt {
    color: #6b7280; /* gray-500 */
    font-size: 1.125rem; /* md:text-lg */
}
#screen .details-column dd {
    font-size: 1.125rem;
    font-weight: 600;
}
#screen .location-section {
    margin: 40px 0;
    width: 100%;
    max-width: 70%;
    height: 14rem;
}
#screen .location-section h1 {
    font-family: serif;
    margin: 20px 0;
    padding-bottom: 5px;
    padding-right: 10px;
    border-bottom: 4px solid #2563eb; /* border-blue-600 */
    font-size: 2.25rem; /* lg:text-4xl */
}
#screen .location-section iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 0;
}











/* ------------------Profile-content-------------------- */
.profile-content-section{
  width : 100%;
}

.profile-content-box{
  width : 45%;
}

@media (max-width : 768px) {
  
}

@media (max-width : 576px) {
  .profile-content-box{
    width : 90%;
  }
}